// eslint-disable-next-line import/prefer-default-export
export const cpLegalSubverticals = {
  title: 'SpotOn terms & policies',
  noMore: true,

  subverticalInfo: [
    {
      iconImg: 'terms.png',
      subverticalName: 'Terms of Services.',
      links: [
        {
          linkTitle: 'Merchants',
          linkTarget: '/legal/merchant-terms',
        },
        {
          linkTitle: 'Users',
          linkTarget: '/legal/user-terms',
        },
        {
          linkTitle: 'Point-of-Sale',
          linkTarget: '/legal/point-of-sale-terms',
        },
        {
          linkTitle: 'SpotOn Website',
          linkTarget: '/legal/website-terms',
        },
        {
          linkTitle: 'Payments',
          linkTarget: "/payments-terms.pdf",
        },
        {
          linkTitle: 'SpotOn Reserve',
          linkTarget: '/legal/reserve-terms',
        },
        {
          linkTitle: "SpotOn Teamwork",
          linkTarget: "/legal/teamwork-terms",
        },
      ],
      subverticalContent:
        'Read our Terms of Service for SpotOn merchants and consumers.',
    },
    {
      iconImg: 'policies.png',
      subverticalName: 'Policies',
      subverticalContent:
        'Explore our privacy statement for details on how we collect and use data.',
      links: [
        {
          linkTitle: 'Merchant Privacy',
          linkTarget: '/legal/merchant-privacy',
        },
        {
          linkTitle: 'Accessibility',
          linkTarget: '/legal/accessibility-statement',
        },
        {
          linkTitle: 'Consumer Privacy',
          linkTarget: '/legal/consumer-privacy',
        },
        {
          linkTitle: 'SMS Terms',
          linkTarget: '/legal/sms-terms-and-conditions',
        },
      ],
    },
  ],
};
