import React from 'react';
import Layout from '../components/Layout/layout';
import SEO from '../components/seo';
import Subverticals from '../components/SubVerticals/Subverticals';
import { cpLegalSubverticals } from '../data/CP-data';

const Legal = () => {
  return (
    <Layout className="about-us">
      <SEO title="SpotOn - Legal" />
      <Subverticals sectionData={cpLegalSubverticals} />
    </Layout>
  );
};

export default Legal;
